/* ЭКРАН СКРОЛЛА */

.panelWrapper {
  position: relative;
  overflow: hidden;
}

.panel {
  overflow: hidden;
  box-sizing: border-box;
  cursor: default;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.panel-text {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 64%;
  padding-left: 70px;
}

.navbar-lang {
  position: absolute;
  right: 64px;
  top: 46px;
  z-index: 5;
  padding: 0;
  display: flex;
  flex-direction: row;
  color: #f3c77d;
  font-size: 18px;
}

.navbar-lang li {
  list-style: none;
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-lang li:hover a {
  color: white;
}

.active-lang a {
  color: #f3c77d;
}

.active-lang:hover a {
  color: #f3c77d;
}

@media screen and (max-height: 848px) {
  .panel-text {
    width: 67%;
  }
}

.panel-text h2 {
  font-weight: 500;
  font-size: 46px;
  line-height: 60px;
  margin-bottom: 39px;
  margin-top: 65px;
}

#meditation .panel-text h2 {
  font-weight: 500;
  font-size: 46px;
  line-height: 50px;
  margin-bottom: 0px;
  margin-top: 65px;
  line-height: 1;
}

#meditation .panel-text h3 {
  font-weight: 500;
  font-size: 26px;
  /* margin-bottom: 16px; */
  line-height: 1;
  margin-block-start: 0em;
  margin-block-end: 1em;
}

@media screen and (max-height: 848px) {
  .panel-text h2 {
    margin-top: 29px;
    font-size: 39px;
    line-height: 45px;
    margin-bottom: 27px;
  }

  #meditation .panel-text h2 {
    font-size: 39px;
    line-height: 39px;
    margin-bottom: 0px;
    margin-top: 29px;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
}

@media screen and (max-width: 1200px) {
  .panel-text h2 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 39px;
    margin-top: 65px;
  }

  #meditation .panel-text h2 {
    font-size: 36px;
    margin-bottom: 0px;
    margin-top: 65px;
    line-height: 1;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
}

@media screen and (max-width: 1200px) {
  .panel-text {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .panel-text {
    padding-left: 0;
  }

  .panel-text h2 {
    font-size: 36px;
    line-height: 40px;
  }

  #meditation .panel-text h2 {
    font-size: 36px;
    margin-bottom: 0px;
    margin-top: 65px;
    line-height: 1;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .panel {
    overflow: auto;
    height: 100%;
  }

  .panel-text h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
    margin-top: 64px;
  }

  #meditation .panel-text h2 {
    font-size: 32px;
    margin-bottom: 0px;
    margin-top: 64px;
    line-height: 1;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }

  .panel-text {
    height: auto;
  }
}

@media screen and (max-height: 420px) and (orientation: landscape) and (min-width: 380px) {
  .panel-text h2 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 540px) {
  .panel-text h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media screen and (max-width: 460px) {
  .panel-text h2 {
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  }

  #meditation .panel-text h2 {

    margin-bottom: 0px;
    margin-top: 1.8rem;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
}

@media screen and (max-width: 375px) {
  .panel-text h2 {
    font-size: 28px;
    line-height: 36px;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  #meditation .panel-text h2 {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 0.8rem;
    line-height: 1;
  }

  #meditation .panel-text h3 {
    font-size: 20px;
    line-height: 1;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
}

@media screen and (max-width: 460px) {
  .panel-text {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.mainScreensWrapper {
  position: relative;
  padding-top: 100px;
}

.screen {
  transition: all 1s ease-in-out;
}

.screenbefore {
  position: absolute;
  transform: translate(0, -100%);
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .screenbefore {
    position: relative;
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

.screenafter {
  position: absolute;
  transform: translate(0, 100%);
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .screenafter {
    position: relative;
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

.screencurrent {
  position: absolute;
  height: 100vh;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .screencurrent {
    position: relative;
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

.mainScreensWrapperImage .sr-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-toggler {
    right: 0px;
  }
}

@media screen and (max-width: 460px) {
  .navbar-toggler {
    right: 21px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .navbar-toggler {
    right: 21px;
    position: fixed;
  }
}

@media screen and (max-width: 340px) {
  .mainScreensWrapper {
    width: 96%;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensWrapper {
    padding-top: 0px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .main-wrapper {
    display: block;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}