
/* @font-face {
  font-family: 'Muller';
  src: local('Muller'), url(/font/MullerRegular.woff) format('woff');
} */


body {
  margin: 0;
  /* font-family: 'Muller', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
