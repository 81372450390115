@media screen {
  .mainScreensWrapperImage {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .mainScreensWrapperImage {
    position: absolute;
    top: 160px;
    left: 831px;
    width: 247px;
    display: block;
  }
}

.mainScreensSinglePageWrapperImage {
  position: relative;
  margin: auto;
  width: 224px;
  display: block;
}
@media screen and (max-width: 1200px) {
  .mainScreensSinglePageWrapperImage {
    margin-left: 19px;
    margin-top: 46px;
    margin-bottom: 27px;
    width: 192px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreensSinglePageWrapperImage {
    margin-left: auto;
    margin-right: auto;
    margin-top: 127px;
    margin-bottom: 45px;
    width: 188px;
  }
}
@media screen and (max-width: 480px) {
  .mainScreensSinglePageWrapperImage {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    margin-bottom: 45px;
    width: 188px;
  }
}
@media screen and (max-width: 460px) {
  .mainScreensSinglePageWrapperImage {
    width: 164px;
    margin-bottom: 14px;
    margin-top: 28px;
  }
}

@media screen and (max-width: 390px) {
  .mainScreensSinglePageWrapperImage {
    width: 152px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 375px) {
  .mainScreensSinglePageWrapperImage {
    width: 128px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensSinglePageWrapperImage {
    width: 183px;
    margin-left: 103px;
  }
}
@media screen and (max-height: 460px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensSinglePageWrapperImage {
    margin-left: 8vw;
  }
}
@media screen and (max-height: 410px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensSinglePageWrapperImage {
    margin-left: 8vw;
  }
}

@media screen and (max-height: 380px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensSinglePageWrapperImage {
    width: 143px;
  }
}
#screenshots {
  position: relative;
  width: 380px;
  margin-left: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}
#screenshots .panel-text {
  width: 380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
}
#screenshots .panel-text p {
  width: 380px;
  margin-left: auto;
  margin-right: auto;
}
#screenshots .wrap-btn {
  width: 390px;
  margin-left: auto;
  margin-right: auto;
}
#screenshots .screenshots-item {
  width: 380px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) {
  #screenshots {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
  #screenshots .panel-text {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-self: flex-start;
    align-items: flex-start;
  }
  #screenshots .panel-text p {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  #screenshots {
    width: 81vw;
    margin-left: auto;
    margin-right: auto;
  }
  #screenshots .panel-text h2 {
    display: none;
  }
  #screenshots .panel-text {
    display: block;
    width: 602px;
  }
  #screenshots .panel-text p {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 576px) and (orientation: portrait) {
  #screenshots {
    width: 88vw;
  }
  #screenshots .panel-text {
    width: 100%;
  }
  #screenshots .panel-text p {
    width: 97%;
  }
  #screenshots .wrap-btn {
    width: 90%;
  }
  #screenshots .panel-text p {
    line-height: 21px;
    font-size: 16px;
    text-align: center;
  }
}
@media screen and (max-width: 460px) and (orientation: portrait) {
  #screenshots {
    width: 100vw;
  }
}
@media screen and (max-width: 375px) and (orientation: portrait) {
  #screenshots .screenshots-item {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  #screenshots .panel-text p {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 375px) {
  #screenshots .panel-text p {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 340px) {
  #screenshots .panel-text p {
    display: none;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  #screenshots {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  #screenshots .wrap-btn {
    display: flex;
  }
  #screenshots .panel-text p {
    display: block;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  #screenshots {
    margin-left: auto;
    margin-right: auto;
  }
  #screenshots .wrap-btn {
    display: flex;
    width: 381px;
  }
  #screenshots .panel-text p {
    display: block;
  }
}

@media screen and (max-height: 460px) and (orientation: landscape) and (min-width: 380px) {
  #screenshots .wrap-btn {
    display: flex;
    margin-bottom: 20px;
  }
  #screenshots .wrap-btn {
    width: 300px;
  }
  #screenshots .panel-text p {
    display: block;
    width: 300px;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
  #screenshots .btn-form {
    width: 140px;
  }
}

.mainScreensWrapperImage .carousel-control-next-icon {
  z-index: 5;
  overflow: visible !important;
  top: 50%;
  bottom: 0px;
  width: 14px;
  height: 18px;
  color: var(--blue);
  background-image: none;
  border: none;
}

.mainScreensWrapperImage .carousel-control-next {
  right: -68px;
  overflow: visible !important;
  z-index: 10 !important;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  top: 45%;
  border: 1px solid #187897;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .mainScreensWrapperImage .carousel-control-next {
    right: -121px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreensWrapperImage .carousel-control-next {
    right: -116px;
  }
}
@media screen and (max-width: 460px) {
  .mainScreensWrapperImage .carousel-control-next {
    right: -82px;
  }
}
@media screen and (max-height: 460px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensWrapperImage .carousel-control-next {
    right: -60px;
  }
}

.mainScreensWrapperImage .carousel-control-prev-icon {
  overflow: visible !important;
  top: 50%;
  bottom: 0px;
  width: 14px;
  height: 18px;
  background-image: none;
  color: var(--blue);
  border: none;
  left: -36px;
}

.mainScreensWrapperImage .carousel-control-prev {
  left: -68px;
  overflow: visible !important;
  z-index: 10 !important;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  top: 45%;
  border: 1px solid #187897;
  border-radius: 50%;
}
@media screen and (max-width: 1200px) {
  .mainScreensWrapperImage .carousel-control-prev {
    left: -121px;
  }
}

@media screen and (max-width: 768px) {
  .mainScreensWrapperImage .carousel-control-prev {
    left: -116px;
  }
}
@media screen and (max-width: 460px) {
  .mainScreensWrapperImage .carousel-control-prev {
    left: -82px;
  }
}
@media screen and (max-height: 460px) and (orientation: landscape) and (min-width: 380px) {
  .mainScreensWrapperImage .carousel-control-prev {
    left: -60px;
  }
}
.sr-only {
  display: none;
}

.screen-tel {
  width: 100%;
  background-size: 100%;
  z-index: 0;
  position: absolute;
}

.screen-wrapper {
  width: 100%;
  height: 100%;
  overflow: visible;
  padding: 15px;
}
@media screen and (max-width: 768px) {
  .screen-wrapper {
    padding: 12px;
  }
}

@media screen and (max-width: 375px) {
  .screen-wrapper {
    padding: 8px;
  }
}
@media screen and (max-width: 375px) {
  .screen-wrapper {
    padding: 8px;
  }
}
@media screen and (max-height: 375px) and (orientation: landscape) and (min-width: 380px) {
  .screen-wrapper {
    padding: 8px;
  }
}
.screen-image {
  width: 100%;
  height: 100%;
  z-index: 20;
}
