/* FORM */

.spacer {
  margin-bottom: 15px;
}

.control-input {
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
  margin-bottom: 15px;
}

#tel {
  position: fixed;
  left: 60%;
  top: 16%;
  width: 270px;
}

.input {
  background: rgba(255, 255, 255, 0.19);
  border: 2px solid #30abd1;
  box-shadow: inset 0px 2px 10px #000000;
  border-radius: 13px;
  height: 50px;
  padding: 10px 30px;
  font-size: 18px;
  line-height: 104%;
  width: 283px;
  margin-right: 1.5rem;
  color: white;
}

.message {
  z-index: 5;
}

.input-message {
  resize: none;
  align-self: flex-start;
  z-index: 5;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 0;
  height: 100%;
  width: 350px;
}

@media screen and (max-width: 1200px) {
  .input {
    width: 296px;
    margin-right: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .input-message {
    width: 466px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .input {
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .control-input {
    margin-bottom: 0;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .input {
    width: 380px;
    margin-right: 19px;
  }
}

@media screen and (max-height: 479px) and (orientation: landscape) and (min-width: 380px) {
  .input {
    width: 35vw;
    margin-right: 19px;
  }
}

@media screen and (max-width: 480px) {
  .input {
    width: 419px;
  }
}

@media screen and (max-width: 460px) {
  .input {
    width: 90vw;
  }
}

.input-mb {
  margin-bottom: 25px;
}

@media screen and (max-width: 390px) {
  .input-mb {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 375px) {
  .input-mb {
    margin-bottom: 2vh;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .input-mb {
    margin-bottom: 25px;
  }
}

.form {
  display: block;
}

@media screen and (max-width: 400px) {
  .form {
    overflow: auto;
  }
}

.input:hover {
  outline: none;
  background: #b17df3;
  border: 2px solid #9c18a8;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 10px #000000;
}

.input:focus {
  outline: none;
  background: rgba(251, 234, 207, 0.87);
  border: 2px solid #f3c77d;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 10px #000000;
}

.btn-center {
  margin-left: auto;
  margin-right: auto;
}

.btn-form {
  background: linear-gradient(180deg, #b17df3 0%, rgba(255, 255, 255, 0) 100%),
    #0b86ac;
  border-radius: 13px;
  height: 46px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .btn-form {
    width: 47%;
  }
}

@media screen and (max-width: 480px) {
  .btn-form {
    width: 180px;
  }
}

@media screen and (max-width: 460px) {
  .btn-form {
    width: 48%;
  }
}

/* @media screen and (max-width: 390px) {
  .btn-form {
    width: 46%;
  }
} */

/* .btn-form:focus {
  background: linear-gradient(
      180deg,
      #fdd89b -5.43%,
      rgba(255, 255, 255, 0) 63.04%
    ),
    #f3c77d;
  box-shadow: 0px 2px 10px #000000;
  border-radius: 13px;
  border: none;
  outline: none;
  color: black;
} */
.btn-form:hover {
  background: linear-gradient(180deg,
      #fdd89b -5.43%,
      rgba(255, 255, 255, 0) 63.04%),
    #f3c77d;
  box-shadow: 0px 2px 10px #000000;
  border-radius: 13px;
  border: none;
  outline: none;
  border: none;
  outline: none;
  color: black;
}

.active-btn .btn-form {
  background: linear-gradient(180deg,
      #fdd89b -5.43%,
      rgba(255, 255, 255, 0) 63.04%),
    #f3c77d;
  box-shadow: 0px 2px 10px #000000;
  border-radius: 13px;
  border: none;
  outline: none;
  border: none;
  outline: none;
  color: black;
}

@media screen and (max-width: 768px) {
  .btn-form:hover {
    background: linear-gradient(180deg, #b17df3 0%, rgba(255, 255, 255, 0) 100%),
      #0b86ac;
    border-radius: 13px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    color: var(--white);
  }

  .btn-form:focus {
    background: linear-gradient(180deg, #b17df3 0%, rgba(255, 255, 255, 0) 100%),
      #0b86ac;
    border-radius: 13px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    color: var(--white);
  }

  .btn-form:active {
    background: linear-gradient(180deg,
        #fdd89b -5.43%,
        rgba(255, 255, 255, 0) 63.04%),
      #f3c77d;
    box-shadow: 0px 2px 10px #000000;
    border-radius: 13px;
    border: none;
    outline: none;
    border: none;
    outline: none;
    color: black;
  }

  .active-btn .btn-form {
    background: linear-gradient(180deg,
        #fdd89b -5.43%,
        rgba(255, 255, 255, 0) 63.04%),
      #f3c77d;
    box-shadow: 0px 2px 10px #000000;
    border-radius: 13px;
    border: none;
    outline: none;
    border: none;
    outline: none;
    color: black;
  }
}

.btn-form-wrap {
  border: 1px solid #16f1ff;
  border-radius: 11px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 18px;
}

.active-btn .btn-form-wrap {
  border: 1px solid #ffefd5;
}

.a {
  color: black;
}

@media screen and (max-width: 400px) {
  .btn-form-wrap {
    font-size: 16px;
  }
}

.btn-form-wrap-img {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3px;
  line-height: 104%;
}

.btn-form-wrap-img svg {
  display: block;
}

/* .btn-form-wrap:focus {
  border: 1px solid #ffefd5;
} */
.btn-form-wrap:hover {
  border: 1px solid #ffefd5;
}

@media screen and (max-width: 768px) {
  .btn-form-wrap:hover {
    border: 1px solid #16f1ff;
  }

  .btn-form-wrap:focus {
    border: 1px solid #16f1ff;
  }

  .btn-form-wrap:active {
    border: 1px solid #ffefd5;
  }

  .active-btn .btn-form-wrap {
    border: 1px solid #ffefd5;
  }
}

.form-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .form-wrap {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .form-wrap {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .form-wrap {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .form-wrap {
    margin-bottom: 0vh;
  }
}

@media screen and (max-width: 375px) {
  .form-wrap {
    margin-bottom: 0vh;
  }
}

.form-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .input-message {
    width: 313px;
  }
}

@media screen and (max-width: 540px) {
  .input-message {
    margin-top: 25px;
  }
}

@media screen and (max-width: 480px) {
  .input-message {
    width: 419px;
    height: 129px;
  }
}

@media screen and (max-width: 480px) {
  .input-message {
    width: 100%;
    height: 50px;
  }
}

@media screen and (max-width: 460px) {
  .input-message {
    width: 90vw;
    height: 50px;
  }
}

@media screen and (max-width: 390px) {
  .input-message {
    margin-top: 16px;
  }
}

@media screen and (max-width: 375px) {
  .input-message {
    margin-top: 2vh;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) and (min-width: 380px) {
  .input-message {
    width: 380px;
  }
}

@media screen and (max-height: 479px) and (orientation: landscape) and (min-width: 380px) and (min-width: 380px) {
  .input-message {
    width: 41vw;
  }
}

.list {
  font-size: 18px;
}

.list-item {
  margin-right: 100px;
}

.list-svg {
  margin-right: 20px;
}

/* Possibility */
.possibility-btn {
  font-size: 18px;
  font-weight: 300;
  padding: 2px;
  display: block;
  width: 24%;
}

.possibility-btn .btn-form {
  width: 100%;
}

@media (max-width: 760px) {
  .possibility-btn {
    width: 47%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.possibility-wrap {
  border: 1px solid #f3c77d;
  filter: drop-shadow(0px 0px 4px #f3c77d);
  border-radius: 25px;
  padding: 29px 33px 15px 33px;
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .possibility-wrap {
    width: 97%;
  }
}

@media (max-width: 768px) {
  .possibility-wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 450px) {
  .possibility-wrap {
    max-height: 25vh;
    overflow: scroll;
  }
}

@media (max-width: 540px) {
  .possibility-wrap {
    max-height: 330px;
    overflow: scroll;
  }
}

@media (max-width: 460px) {
  .possibility-wrap {
    max-height: 25vh;
    padding: 0.5rem;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .possibility-wrap {
    padding: 29px 33px 15px 33px;
    height: 350px;
    margin-left: 0;
    margin-right: 0;
    width: 99%;
  }
}

.possibility-block-btn {
  width: 102%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .possibility-block-btn {
    width: 99%;
  }
}

@media (max-width: 768px) {
  .possibility-block-btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 760px) {
  .possibility-block-btn {
    flex-wrap: wrap;
  }
}

@media (max-width: 390px) {
  .possibility-block-btn {
    margin-bottom: 5px;
  }
}

@media (max-width: 375px) {
  .possibility-block-btn {
    margin-bottom: 0;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .possibility-block-btn {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.possibility-wrap p {
  font-size: 18px;
  line-height: 21px;
  color: var(--white);
  font-weight: 300;
  margin-bottom: 14px;
}

@media (max-width: 1200px) {
  .possibility-wrap p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .possibility-wrap p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 14px;
  }
}

@media (max-width: 768px) {
  .possibility-wrap p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 400px) {
  .possibility-wrap p {
    font-size: 16px;
    line-height: 16px;
    margin-left: 8px;
  }
}

/* Review */
.review-wrap {
  border: 1px solid #20b9e9;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 4px #20b9e9);
  border-radius: 25px;
  padding: 29px 29px 29px 29px;
}

@media (max-width: 768px) {
  .review-wrap {
    padding: 29px 29px 29px 14px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .review-wrap {
    height: 175px;
  }
}

.review-wrap p {
  font-size: 18px;
  line-height: 21px;
  color: var(--white);
  margin-left: 30px;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .review-wrap p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 768px) {
  .review-wrap p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 400px) {
  .review-wrap p {
    font-size: 16px;
    line-height: 21px;
    max-height: 25vh;
    overflow: scroll;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .review-wrap p {
    font-size: 16px;
    line-height: 21px;
  }
}

.review-wrap img {
  border: 2px solid #20b9e9;
  border-radius: 50%;
  width: 59px !important;
  height: 59px !important;
}

.review-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-name p {
  font-weight: 500;
  font-size: 12px;
  line-height: 104%;
  color: var(--blue);
  padding-top: 10px;
  margin-left: 0;
  width: 59px;
  text-align: center;
}

.stars {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.stars svg {
  display: block;
  margin-left: 8px;
}

.stars p {
  margin-bottom: 17px;
}

/* СЛАЙДЕР */
.button-prev {
  color: var(--blue);
  position: absolute;
  top: 43%;
  left: -23%;
  border-radius: 50%;
  border: 2px solid var(--blue);
  width: 40px;
  height: 40px;
  background: none;
}

.button-prev:hover {
  color: var(--turquoise);
  outline: none;
  border: 2px solid var(--turquoise);
}

.button-prev:focus {
  color: var(--turquoise);
  outline: none;
  border: 2px solid var(--turquoise);
}

.button-next:hover {
  color: var(--turquoise);
  outline: none;
  border: 2px solid var(--turquoise);
}

.button-next:focus {
  color: var(--turquoise);
  outline: none;
  border: 2px solid var(--turquoise);
}

.button-next {
  color: var(--blue);
  position: absolute;
  top: 43%;
  left: 100%;
  border-radius: 50%;
  border: 2px solid var(--blue);
  width: 40px;
  height: 40px;
  background: none;
}

.carousel-indicators li {
  border: 1px solid var(--blue);
  filter: drop-shadow(0px 0px 4px var(--blue));
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  left: 50%;
  content: ' ';
  top: 23px;
  margin-left: 10px;
  margin-right: 10px;
  background: none;
}

.carousel-indicators .active {
  background: var(--white);
  border: 1px solid var(--white);
}

.carousel-indicators {
  bottom: -83px;
}

@media (max-width: 390px) {
  .carousel-indicators {
    bottom: -65px;
  }
}

@media (max-width: 375px) {
  .carousel-indicators {
    bottom: -50px;
  }
}

/* meditation */
#meditation p {
  line-height: 21px;
  font-size: 18px;
}

.meditation-items {
  margin-bottom: 46px;
  margin-right: 48px;
  box-sizing: border-box;
  /* padding: 15px; */
}



.meditation-items p {
  margin-bottom: 14px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);

  /* grid-gap: 10px; */
  /* grid-auto-rows: minmax(100px, auto); */
}

.grid li {
  list-style: disc;
  margin-left: 16px;
}


@media screen and (max-height: 848px) {
  .meditation-items {
    margin-bottom: 19px;
    margin-right: 0px;
  }


}

@media screen and (max-width: 1200px) {
  #meditation p {
    line-height: 21px;
    font-size: 16px;
  }

  .meditation-items p {
    font-size: 16px;
    line-height: 21px;
  }

  .grid {
    display: block;
    grid-template-columns: none;
  }
}

@media (max-width: 576px) {
  .meditation-items p {
    font-size: 16px;
    line-height: 21px;
  }

  #meditation p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 576px) {
  .meditation-items {
    height: 318px;
    overflow: scroll;
    margin-bottom: 60px;
  }
}

@media (max-width: 460px) {
  .meditation-items {
    height: 25vh;
    overflow: scroll;
    margin-bottom: 60px;
  }
}

@media (max-width: 390px) {
  .meditation-items {
    margin-bottom: 40px;
  }
}

@media (max-width: 375px) {
  .meditation-items {
    margin-bottom: 30px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) and (min-width: 380px) {
  .meditation-items p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 14px;
  }

  #meditation p {
    font-size: 16px;
    line-height: 21px;
  }
}

/* modal */

.modal-text p {
  color: black;
}

.modal-text {
  color: black;
}

.close {
  background: none;
  color: black;
  border: none;
}